<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <ShopTabs/>
        <div class="d-flex justify-space-between align-center mb-5">
          <h3 class="font-semibold text-blue">
            Available Academies
          </h3>
          <div class="d-flex align-center gap-x-2">
            <v-text-field
                v-model="search"
                class="search"
                clearable
                dense
                flat
                hide-details="auto"
                label="Search"
                solo
                @keyup="searchAcademy"
                @click:append="searchAcademy"
                @click:clear="clearSearch"
            >
              <template #prepend-inner>
                <SearchIcon/>
              </template>
            </v-text-field>
            <v-autocomplete
                v-model="venueServiceIds"
                :items="venueServices"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="name"
                item-value="venue_service_id"
                multiple
                outlined
                placeholder="Services*"
                style="width: 200px;height: 40px"
                @change="changeVenueService()"
            >
              <template v-if="venueServiceIds.length == venueServices.length" v-slot:selection="{ index }">
                <span v-if="index == 0">All Services</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index == 0">{{ item.name }}</span>
                <span v-if="index == 1">, {{ item.name }}</span>
                <span v-if="index == 2">, ...</span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle()">
                  <v-list-item-action>
                    <v-icon :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''">{{ icon() }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <v-row>
          <v-col v-for="academy in academies" :key="academy.id" lg="4" md="6" sm="12" xl="3">
            <ShopAcademyCard :academy="academy" :defaultImages="defaultImages"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import SearchIcon from "@/assets/images/misc/search.svg";
import ShopAcademyCard from "@/components/Shop/Academy/ShopAcademyCard.vue";
import images from "@/utils/images";

export default {
  name: "ShopAcademy",
  components: {ShopAcademyCard, ShopTabs, SearchIcon},
  data() {
    return {
      defaultImages: images,
      search: "",
      academies: [],
      venueServiceIds: [],
      page: 1,
      totalPages: 1,
      refreshProductItem: 0,
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getShopVenueServices.data.filter(
          (service) => service.name != "POS"
      );
    },
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    }
  },
  watch: {
    page() {
      this.loadAcademies();
    },
    reloadShopModule(val){
      if(val && val === 'academy'){
        this.page = 1;
        this.totalPages = 1;
        this.loadAcademies();  
      }
    }
  },
  mounted() {
    if (this.$store.getters.getTaxTypes.status === false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getWeekdays.status === false) {
      this.$store.dispatch("loadWeekdays");
    }
    this.loadAcademies();
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.loadAcademies();
    },
    searchAcademy() {
      let s = this.search;
      if (s.length > 3) {
        this.loadAcademies(s);
      }
    },
    loadAcademies(search = "") {
      const venueId = this.getCurrentVenueId;
      this.showLoader("Loading..");
      let url = this.venueServiceIds
          .map((item, index) => `venue_service_ids[${index}]=${item}`)
          .join(",");
      let s = "";
      if (search) {
        s = `&name=${search}`;
      }
      this.$http.get(`venues/shop/academies?venueId=${venueId}&status_id=1&${url}${s}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.academies = response.data.data;
              this.totalPages = response.data.total_pages;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    changeVenueService() {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.loadAcademies();
      }
    },
    icon() {
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      if (this.venueServiceIds.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
              (item) => item.venue_service_id
          );
          this.$forceUpdate;
        }
      });
      setTimeout(() => {
        this.page = 1;
        this.loadAcademies();
      });
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  border-radius: 4px;
  border: 1px solid #EFEDED;
  background: #FFF;
  max-width: 250px;

  .v-label {
    opacity: 0.3;
  }
}
</style>